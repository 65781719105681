<style scoped>

</style>

<template>
  <div id="main-container">
    <div style="display: flex; flex-direction: column;gap: 8px;">

      <BaseSelect
        v-model="selectedStateType"
        label="Type d'état à générer"
        name="selectedStateType"
        fieldValue="value" 
        fieldLabel="label"
        style="min-width:360px;"
        :defaultOptions="statesTypes"
        @onChange="onSelectedStateTypeChanged"
      />
      <div v-if="selectedStateType !== null">
        <div 
          v-for="(filter) in filters"
          :key="filter.name"
        >
          <BaseDatepicker
            v-model="filterValues[filter.name]"
            v-if="filter.type === 'date' || filter.type === 'month'"
            :label="filter.label"
            :required="!filter.nullable"
            :onlyMonth="filter.type === 'month'"
          />
        </div>
        <div style="min-height: 25px;"></div>
          <BaseButton
            class="rectangle-button white-button big-button"
            buttonText="Télécharger état"
            @click="downloadSelectedState"
          >
            <template v-slot:iconBefore>
              <font-awesome-icon :icon="['fas', 'pen']"/>
            </template>
          </BaseButton>
      </div>
    </div>
  </div>
  <Dialog ref="dialog"/>
</template>

<script>
import axios from 'axios';
import BaseSelect from '@/components/Base/BaseSelect';
import CustomTopbar from '@/components/Topbar/CustomTopbar';
import Dialog from '@/components/App/Dialog'
import 'jquery-ui-dist/jquery-ui';
import Modal from "@/components/App/Modal";
import BaseDatepicker from '@/components/Base/BaseDatepicker';
import BaseRadioButton from "@/components/Base/BaseRadioButton";
import RadioButtonContainer from "@/components/App/RadioButtonContainer";
import BaseInput from "@/components/Base/BaseInput";
import BaseButton from '@/components/Base/BaseButton';

let context = null;

const e_StateType = {
  stateCaPerCustomer : {value:"stateCaPerCustomer",label: "Chiffre d'affaire par client"},
  stateVariationsStock : {value:"stateVariationsStock",label:"Variation des stocks (1)"},
  stateVariationsStock2 : {value: "stateVariationsStock2",label: "Variation des stocks (2)"},
  stateHeuresParAffaireParMois : {value:"stateHeuresParAffaireParMois",label:"Heures / affaires / mois"},
  stateFacturationParMois : {value: "stateFacturationParMois",label: "facturation / mois"},
  stateClientsCetrafact : {value: "stateClientsCetrafact",label: "Liste clients pour Cetrafact"},
}

export default {
  name: 'stateGenerator',
  components: {
    BaseInput,
    BaseRadioButton,
    Modal,
    BaseButton,
    BaseSelect,
    CustomTopbar,
    BaseDatepicker,
    Dialog,
    RadioButtonContainer
  },
  data() {
    return {
      selectedStateType: null,
      statesTypes : [],
      filters : [],
      filterValues: [],
    }
  },
  mounted() {
    context = this;
    this.statesTypes = Object.keys(e_StateType).reduce((statesTypes, key) => {
      statesTypes.push(
        {
          value: e_StateType[key].value,
          label: e_StateType[key].label
        });
      return statesTypes;
    }, []);
    console.log(this.statesTypes);
  },
  methods: {
    downloadStateCaPerCustomer(){let params = '';
      if(this.filterValues['date_debut']){
        params += `${(params === '' ? "?" : "&" )}startDateFilter=${this.filterValues['date_debut']}`
      }
      if(this.filterValues['date_fin']){
        params += `${(params === '' ? "?" : "&" )}endDateFilter=${this.filterValues['date_fin']}`
      }

      axios.get('stateGenerator/getCaParClient' + params, {
        showPreloader: true
      })
      .then((response) => {
        const rowTitles = [
          "Intitulé", "Montant", "Pays", "Ass.Credit", "Date Exp.", "No Facture", "Date Fact."
        ];
        this.downloadCsvFile(response.data.data, rowTitles, "Etat_ca_par_client");
      })
      .catch(() => {
      });
    },
    downloadStateVariationsStock(){
      let params = '';
      if(this.filterValues['date_debut']){
        params += `${(params === '' ? "?" : "&" )}startDateFilter=${this.filterValues['date_debut']}`
      }
      if(this.filterValues['date_fin']){
        params += `${(params === '' ? "?" : "&" )}endDateFilter=${this.filterValues['date_fin']}`
      }

      axios.get('stateGenerator/getVariationsStock'+params, {
        showPreloader: true
      })
      .then((response) => {
        const rowTitles = [
          "Année Mvt",
          "Code+CC",
          "Famille",
          "Libellé 01",
          "Libellé 02",
          "Nature Art",
          "Type Art",
          "Code Gest.",
          "Jour Mvt",
          "Lib.Mvt",
          "N° Affaire",
          "qte Entrées",
          "qte Sorties",
          "Valeur entrées",
          "Valeur sorties"
        ];


        this.downloadCsvFile(response.data.data, rowTitles, "Etat_variation_stocks");
      })
      .catch(() => {
      });
    },
    downloadStateVariationsStock2(){let params = '';
      if(this.filterValues['date_debut']){
        params += `${(params === '' ? "?" : "&" )}startDateFilter=${this.filterValues['date_debut']}`
      }
      if(this.filterValues['date_fin']){
        params += `${(params === '' ? "?" : "&" )}endDateFilter=${this.filterValues['date_fin']}`
      }
      axios.get('stateGenerator/getVariationsStock2' + params, {
        showPreloader: true
      })
      .then((response) => {
        const rowTitles = [
          "Code Article",
          "Libellé",
          "Libellé sur devis",
          "Qte Stock",
          "Prix Achat",
          "Prix Vente",
          "Date mouvement",
          "Délai",
          "Cpt Achat",
          "Cpt Vente",
          "Affiché sur le devis ?",
          "Géré en stock ?",
          "Famille",
          "Unité",
          "Etablissement",
          "Nature",
          "Qte Entrées",
          "Qte Sorties"
        ];
        this.downloadCsvFile(response.data.data, rowTitles, "Etat_variation_stocks_2");
      })
      .catch(() => {
      });
    },
    downloadStateHeuresParAffaireParMois(){
      let params = '';
      axios.get('stateGenerator/getHeuresParAffaireParMois' + params, {
        showPreloader: true
      })
      .then((response) => {
        const rowTitles = [
          "N° AFFAIRE",
          "NOM CLIENT",
          "Lib 01 PF",
          "Lib 01 Cpt",
          "Tps Realis",
          "Matricule",
          "Nom Matricule",
          "Date Réalisation"
        ];
        this.downloadCsvFile(response.data.data, rowTitles, "Etat_heures_par_affaire_par_mois");
      })
      .catch(() => {
      });
    },
    downloadStateFacturationParMois(){
      let params = '';
      if(this.filterValues['mois']){
        params += `${(params === '' ? "?" : "&" )}monthFilter=${this.filterValues['mois']}`
      }
      axios.get('stateGenerator/getFacturationParMois' + params, {
        showPreloader: true
      })
      .then((response) => {
        const rowTitles = [
          "N° FACTURE",
          "N° DE COMPTE CLIENT",
          "NOM CLIENT",
          "TTC",
          "TVA",
          "HT OU COMPTE"
        ];
        this.downloadCsvFile(response.data.data, rowTitles, "Etat_facturation_par_mois");
      })
      .catch(() => {
      });
    },
    downloadStateClientsCetrafact(){
      axios.get('stateGenerator/getClientsCetrafact', {
        showPreloader: true
      })
      .then((response) => {
        const rowTitles = [
          "Compte",
          "Abrege",
          "Nom",
          "Type tiers",
          "Decouvert1",
          "Date Debut",
          "Date Fin 1",
          "E-Mail",
          "Teleph. 1",
          "Site Inter",
          "Cp Compt P",
          "Rue1 Fact.",
          "Rue2 Fact.",
          "CP Facturé",
          "Ville Fact",
          "Pays Fact.",
          "Rue1",
          "Rue2",
          "Code Post.",
          "Ville",
          "Pays",
          "Nom Livr.",
          "Rue1 Livr.",
          "Rue2 Livr",
          "C. P. Livr",
          "Ville Livr",
          "Pays Livr.",
          "Siret",
          "No TVA CEE",
          "APE",
          "Cpt Compta",
          "Sect.Activ",
          "Code Tarif",
          "Code Taxe",
          "Rég.Fisc.",
          "Langue",
          "Code Rglt",
          "Code Echea",
          "Base Echea"
        ];
        this.downloadCsvFile(response.data.data, rowTitles, "Etat_clients_cetrafact");
      })
      .catch(() => {
      });
    },
    downloadSelectedState(){
      switch(this.selectedStateType.value){
        case (e_StateType.stateCaPerCustomer.value): {
            this.downloadStateCaPerCustomer();
            break;
          }
          case (e_StateType.stateVariationsStock.value): {
            this.downloadStateVariationsStock();
            break;
          }
          case (e_StateType.stateVariationsStock2.value): {
            this.downloadStateVariationsStock2();
            break;
          }
          case (e_StateType.stateHeuresParAffaireParMois.value): {
            this.downloadStateHeuresParAffaireParMois();
            break;
          }
          case (e_StateType.stateFacturationParMois.value): {
            this.downloadStateFacturationParMois();
            break;
          }
          case (e_StateType.stateClientsCetrafact.value): {
            this.downloadStateClientsCetrafact();
            break;
          }
        default:
          break;
      }
    },
    downloadCsvFile(rowsFromServer, rowTitles, filenamePrefix){

      let csvContent = rowsFromServer
        .reduce((o,row) => {
          const keys = Object.keys(row);
          if(o.length === 0) {
            o.push(rowTitles);
          }

          o.push(keys.reduce((obj, key)=> {
            obj.push(row[key] !== null ? `${(''+row[key]).replace(';','').replace(',','')}` : '');
            return obj;
          },[]));

          return o;

        },[])
        .map(e => e.join(";")).join("\n");

      this.exportCSV(csvContent, `${filenamePrefix}_`, true);

    },
    onSelectedStateTypeChanged(input){
      if(input.value === null){
        this.filters = [];
      }
      else {
        this.filters = this.getFiltersByStateType(input.value);
      }
      
      
    },
    getFiltersByStateType(stateType){
      if(stateType){
        switch(stateType.value){
          case (e_StateType.stateCaPerCustomer.value): {
            return [
              {
                label: "Date de début",
                name : "date_debut",
                type : "date",
                nullable: true
              },
              {
                label: "Date de fin",
                name : "date_fin",
                type : "date",
                nullable: true
              }
            ];
          }
          case (e_StateType.stateVariationsStock.value): {
            return [
              {
                label: "Date de début",
                name : "date_debut",
                type : "date",
                nullable: true
              },
              {
                label: "Date de fin",
                name : "date_fin",
                type : "date",
                nullable: true
              }];
          }
          case (e_StateType.stateHeuresParAffaireParMois.value): {
            /*
            return [
              {
                label: "Mois",
                name : "mois",
                type : "month",
                nullable: true
              }
            ];
            */
            return [];
          }
          case (e_StateType.stateFacturationParMois.value): {
            return [
              {
                label: "Mois",
                name : "mois",
                type : "month",
                nullable: true
              }
            ];
          }
          case (e_StateType.stateClientsCetrafact.value): {
            return [];
          }
          case (e_StateType.stateVariationsStock2.value): {
            return [
              {
                label: "Date de début",
                name : "date_debut",
                type : "date",
                nullable: true
              },
              {
                label: "Date de fin",
                name : "date_fin",
                type : "date",
                nullable: true
              }];
          }
        }
      }
      
      return [];
    }

  }
}
</script>
